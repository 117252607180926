<template>
  <div class="content-box">
    <div class="btc-banner">
      <img src="@/assets/image/est/btcest-query.png">
    </div>
    <div class="content-button">
      <div v-for="(item, index) in buttons" :key="index" :class="{ active: current === index }" class="hand" @click="handleButton(index)">
        {{ item }}
      </div>
    </div>
    <updates v-if="current === 0"/>
    <outlines v-if="current === 1"/>
    <approved-plant v-if="current === 2"/>
    <historical-update v-if="current === 3"/>
  </div>
</template>

<script>
import Updates from './updates'
import Outlines from './outlines'
import ApprovedPlant from './approved-plant'
import HistoricalUpdate from './historical-update'

export default {
  name: 'BTCest',
  components: {
    Updates,
    Outlines,
    ApprovedPlant,
    HistoricalUpdate
  },
  data () {
    return {
      buttons: ['Updates', 'Approved Plant', 'Overview', 'Timetable'],
      current: 0
    }
  },
  methods: {
    handleButton (index) {
      this.current = index
    }
  }
}
</script>

<style lang="scss" scoped>
.content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.btc-banner{
  height: 220px;
  width: 100%;
  border-bottom: 2px solid #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
}

.content-button {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0 20px 0;
  border-bottom: 2px solid #f5f5f5;
}
.content-button > div{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 154px;
  margin: 0 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 1px 1px 1px #e1e1e1;
  border: 1px solid #bdbdbd;
  font-weight: bold;
  transition: all 0.2s;
}

.active {
  color: #ffffff;
  background-color: #014379!important;
}
</style>
